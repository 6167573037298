import React from 'react'

import Layout from '../components1/layout'
import Seo from '../components1/seo'

const NotFoundPage = (props) => {
  
    return (
      <Layout location={props.location}>
        <Seo title="404: Not Found" />
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )
  
}

export default NotFoundPage
